import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UserContext from './Auth/UserContext';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import awsConfig from './Auth/AuthConfig';

function Nav() {
  const userContext = useContext(UserContext);
  const [code, setCode] = useState("already been generated");
  const [token, setToken] = useState("");

  const { user, isLoaded, updateCurrentUser } = userContext;
  const isAuthenticated = user && user.username;
  const getCode = async (token) => {
    try {
      let endpoint = awsConfig[`${awsConfig['env']}_endpoint`]
      const response = await axios.post(`${endpoint}/${awsConfig['env']}/auth/code`, token);
      console.log(response);
      setCode(response.data.data)
      setToken(token)
    } catch(error){
      console.log(error);
    };
  };

  const signInUser = ()=>{
    window.location.href = awsConfig[awsConfig['env']]['login_url'];
  }

  if(isAuthenticated && sessionStorage.getItem("code_created") == null){
      Auth.currentSession().then(data => getCode({'token': data.accessToken.jwtToken}));
      sessionStorage.setItem("code_created", true)
  }

  useEffect(() => {
    setTimeout(() => {
      Auth.currentSession().then(data => setToken(data.accessToken.jwtToken));
    }, 1000);
  });

  return (
    <div className="nav-bar" style={{textAlign: "center"}}>
      <div className="nav-bar-body">
        {
          isAuthenticated && isLoaded
            ? (
              <div>
                <div className="nav-bar_username">
                  <span>User</span>
                  {user.username}
                </div>
                <div className="nav-bar_menu">
                  <p>Your Auth Code is <b>{code}</b></p>
                  <p>Your Auth Token is <b>{token}</b></p>
                  
                </div>
              </div>
            )
            : (<button className='btn btn-primary btn-md' onClick={signInUser}>Sign In</button>)
        }
        
      </div>
    </div>
  );
}

export default Nav;