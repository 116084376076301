import React from 'react';
import {
  Switch, Route, useLocation,
} from 'react-router-dom';
import Nav from './components/Nav';
// import Home from './pages/Home';

export default function Routes() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Nav} />
      </Switch>
    </>
  );
}
