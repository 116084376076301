const awsConfig = {
  dev_endpoint: '{{DEV_APIG_ENDPOINT}}',
  staging_endpoint: 'https://gwnuw0kjwj-vpce-0ff6a5c022da35caf.execute-api.eu-central-1.amazonaws.com',
  dev: {
    Auth: {
      identityPoolId: '',
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_Yj3sYNgPr',
      userPoolWebClientId: '6jpoqhhevh88e2ulrvitk6mhc5',
    },
    oauth: {
      domain: 'dlk-pool.auth.eu-central-1.amazoncognito.com',
      scope: ['email', 'openid'],
      redirectSignIn: 'https://archi-dev.aws.tecalliance.net/',
      redirectSignOut: 'https://archi-dev.aws.tecalliance.net/',
      responseType: 'code',
    },
    login_url: "https://dlk-pool.auth.eu-central-1.amazoncognito.com/oauth2/authorize?client_id=6jpoqhhevh88e2ulrvitk6mhc5&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Farchi-dev.aws.tecalliance.net%2F"
  },
  staging: {
    Auth: {
      identityPoolId: '',
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_FhBSdxr9T',
      userPoolWebClientId: '42qp81g8a4mvfuoqodccbgqa8h',
    },
    oauth: {
      domain: 'dlk-user-pool.auth.eu-central-1.amazoncognito.com',
      scope: ['email', 'openid'],
      redirectSignIn: 'https://archi-staging.aws.tecalliance.net/',
      redirectSignOut: 'https://archi-staging.aws.tecalliance.net/',
      responseType: 'code',
    },
    login_url: "https://dlk-user-pool.auth.eu-central-1.amazoncognito.com/oauth2/authorize?client_id=42qp81g8a4mvfuoqodccbgqa8h&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Farchi-staging.aws.tecalliance.net%2F"
  },
  env: "staging"
};

export default awsConfig;
